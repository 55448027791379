import { graphql, useStaticQuery } from "gatsby"
import tw, { styled } from "twin.macro"

import DesktopNavigation from '@root/DesktopNavigation'
import MobileNavigation from '@root/MobileNavigation'
import React from "react"
import { useBreakpoint } from '@hooks/useBreakpoint'

const TopBarContainer = styled.div`
  ${tw`fixed w-full h-0 `}
  z-index: 100;
`

const NavBar = () => {
  const data = useStaticQuery(graphql`
  query MainMenue {
    wpMenu(slug: {eq: "main-menu"}) {
      id
      menuItems {
        nodes {
          id
          path
          label
          parentId
          childItems {
            nodes {
              id
              label
              path
              parentId
              childItems {
                nodes {
                  id
                  label
                  path
                  parentId
                }
              }
            }
          }
        }
      }
      slug
    }
  }
  `)
  const { isMobile } = useBreakpoint()

  // const menuItems = data.wpMenu.nodes[0]?.menuItems?.nodes
  const menuItems = data.wpMenu.menuItems.nodes.filter(
    menuItem => menuItem.parentId === null
  )
  // console.log('MenuItems:', menuItems);
  if(!menuItems) return null;
  return (
    <TopBarContainer>
      { typeof window !== 'undefined' && isMobile && (<MobileNavigation menuItems={menuItems} />)} 
      { typeof window !== 'undefined' && !isMobile && (<DesktopNavigation menuItems={menuItems} />)} 
    </TopBarContainer>
  )
}

export default NavBar








































// const TopBarContainer = styled(motion.div)`
//   ${tw`fixed flex flex-row justify-end w-full h-20 px-5 text-black bg-gray-800 //`}
//   ${({ isDark, isMobile}) => isMobile ? isDark ? tw`text-white bg-black` : tw`text-black bg-white` : tw`bg-transparent`}
//   z-index: 100;
// `
// const Nav = styled.nav`
//   ${tw`absolute flex justify-end hidden w-full // top-40`}
// `

// const Ul = styled.ul`
//   ${tw`flex flex-col lg:flex-row `}
// `

// const Li = styled.li`
//   ${tw`pl-5`}
// `

// const ToggleMenuButton = styled.button`
//   ${tw`self-center // font-primary focus:outline-none`}
//   font-size: 16px;
// `


// const NavBar = () => {
//   const { isMobile } = useBreakpoint();
  
//   const toggleMenu = () => setMenuOpen(isMobile ? !menuOpen : false)
    
//   const [ menuOpen, setMenuOpen ] = useState(false)

//   const data = useStaticQuery(graphql`
//   query MainMenue {
//     allWpMenu(filter: {slug: {eq: "main-menu"}}) {
//       nodes {
//         menuItems {
//           nodes {
//             id
//             path
//             label
//           }
//         }
//         slug
//       }
//     }
//   }
//   `)
//   const stateContext = useStateContext()
//   const menuItems = data.allWpMenu.nodes[0].menuItems.nodes
//   return (
//     <TopBarContainer
//       isDark={stateContext.state.menuMode === 'dark'}
//       menueOpen={menuOpen}
//       isMobile={isMobile}
//       animate={{ height: (menuOpen ? 160 : 80) }}
//       transition={{ type: "spring", velocity: 10, duration: 0.2 }}
//     >
//       <Logo enlarged={ isMobile && menuOpen }/>
//       <Nav css={ menuOpen ? tw`block` : tw`hidden` }>
//         <Ul>
//           {
//             menuItems.map(item => {
//               return (
//                 <Li key={item.id}>
//                   <Link to={item.path}>{item.label}</Link>
//                 </Li>
//                 )
//               }
//             )
//           }
//         </Ul>
//       </Nav>
//       <ToggleMenuButton onClick={() => toggleMenu()} css={isMobile ? tw`block` : tw`hidden`}>Menu</ToggleMenuButton>
//       {/* <p>{isMobile ? 'isMobile' : 'is not mobile'} | { isMobile && menuOpen ? 'enlarged' : 'not enlarged'}</p> */}
//     </TopBarContainer>
//   )
// }

// NavBar.propTypes = {
//   siteTitle: PropTypes.string,
// }
  
// NavBar.defaultProps = {
//   siteTitle: ``,
// }

// export default NavBar
